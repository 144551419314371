import React, { useState, useMemo } from 'react'

const CURRENT = 0
const PREVIOUS = 1

export const BodyDiff = ({ current, previous }) => {
	const [show, updateShow] = useState(CURRENT)

	const handleCurrent = () => updateShow(CURRENT)
	const handlePrevious = () => updateShow(PREVIOUS)

	const currentStr = useMemo(() => (current ? JSON.stringify(current, undefined, 3) : 'not available'), [current])
	const previousStr = useMemo(() => (previous ? JSON.stringify(previous, undefined, 3) : 'not available'), [previous])

	return (
		<React.Fragment>
			<ul className='nav nav-tabs'>
				<li className='nav-item'>
					<span className={`nav-link ${show === CURRENT && 'active'}`} style={{ cursor: 'pointer' }} onClick={handleCurrent}>
						Current
					</span>
				</li>
				<li className='nav-item'>
					<span className={`nav-link ${show === PREVIOUS && 'active'}`} style={{ cursor: 'pointer' }} onClick={handlePrevious}>
						Previous
					</span>
				</li>
			</ul>
			<div className='border-bottom border-start border-end'>
				<div className='my-2 p-2 border-bottom'>Payload</div>

				{show === CURRENT && (
					<div className='p-4'>
						<pre>{currentStr}</pre>
					</div>
				)}
				{show === PREVIOUS && (
					<div className='p-4'>
						<pre>{previousStr}</pre>
					</div>
				)}
			</div>
		</React.Fragment>
	)
}
