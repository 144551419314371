import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Card } from '../../../../../../shared/card/card'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { useDataContext } from '../../data-context'
import { NodeCategoryMenuFull } from '../common/node-category-menu'
import { NodeTags } from '../../common/shared/node-tags'
import { ThumnailAsset } from '../../common/thumbnail/thumbnail-asset'

export const NodeCategoryEdit = ({ parentURL, nodeID, useApiForm }) => {
  const apiForm = useApiForm()

  const dataContext = useDataContext()

  const handleMatch = async () => {
    await apiForm.get(nodeID)
  }

  const handleSubmit = async data => {
    let node = await apiForm.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  const HeaderMenu = () => NodeCategoryMenuFull(parentURL, apiForm.form)

  return (
    <BreadcrumbView Icon={IconEdit} text="edit" onMatch={handleMatch}>
      <CardFullHeightScrollY title={apiForm.form.name} HeaderMenu={HeaderMenu}>
        <Card>
          <Form state={apiForm.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" />
            <FormSubmit text="update" />
          </Form>
        </Card>

        <NodeTags apiForm={apiForm} />

        <ThumnailAsset nodeID={nodeID} />
      </CardFullHeightScrollY>
    </BreadcrumbView>
  )
}
