import React, { useMemo, useRef } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { BadgeInfo, BadgeSecondary, BadgeSuccess, BadgeWarning } from '../../../../../shared/component/badge'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnDate, ColumnFormat, ColumnMenu, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import moment from 'moment'
import { IconAnalytic, IconAssessment, IconAuthLogin, IconContent, IconGroup, IconUsers } from '../../../../../shared/icon/icon'
import { useApiListUserLogin } from '../../../../../api-new/state/content'
import { useRoleOrgGroupUser, useRoleOrgNodeUser, useRoleOrgUser } from '../../../../../role/hook/role-org'

const BadgeStatus = ({ userLogin }) => {
  const value = useMemo(
    () => {
      if (userLogin.invitePending) {
        return 'invited'
      }

      if (userLogin.loginLastAuth) {
        let loginLastAuth = moment(userLogin.loginLastAuth)
        let minutes = moment().diff(loginLastAuth, 'minutes')

        if (minutes < 10) {
          return 'online'
        } else if (minutes < 30) {
          return 'away'
        }
      }

      return 'offline'
    },
    [userLogin]
  )

  if (value === 'invited') {
    return <BadgeInfo value="invited" />
  } else if (value === 'online') {
    return <BadgeSuccess value="online" />
  } else if (value === 'away') {
    return <BadgeWarning value="away" />
  } else if (value === 'offline') {
    return <BadgeSecondary value="offline" />
  }
}

export const UserLoginList = () => {
  const apiListUserLogin = useApiListUserLogin()

  const roleOrgUser = useRoleOrgUser()
  const roleOrgNodeUser = useRoleOrgNodeUser()
  const roleOrgGroupUser = useRoleOrgGroupUser()

  const handleReady = async () => await apiListUserLogin.get()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add users" to="/org/user/list/add" show={roleOrgUser.accessCreate} />
      <MenuRecycle to="/org/user/list/recycle" show={roleOrgUser.accessDelete} />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to="/org/user/list/activity-all" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconUsers} text="USERS">
        <CardFullHeightScrollY title="Users" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListUserLogin} onReady={handleReady}>
            <Column field="email" sortable />
            <Column field="firstName" sortable />
            <Column field="lastName" sortable />

            <ColumnDate field="createdAt" sortable filter />

            <ColumnFormat header="Status" selector={row => <BadgeStatus userLogin={row} />} />

            <ColumnFormat
              field="loginLastAuth"
              header="Last Active"
              selector={row => (row.loginLastAuth ? moment(row.loginLastAuth).fromNow(true) : 'Never')}
              sortable
            />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/org/user/list/edit/${row.userID}`} show={roleOrgUser.accessUpdate} />
                  <MenuItem Icon={IconContent} text="contents" to={`/org/user/list/edit/${row.userID}/content`} show={roleOrgNodeUser.accessRead} />
                  <MenuItem Icon={IconGroup} text="groups" to={`/org/user/list/edit/${row.userID}/group`} show={roleOrgGroupUser.accessRead} />
                  <MenuItem
                    text="analytics custom"
                    Icon={IconAnalytic}
                    to={`/org/user/list/edit/${row.userID}/analytic-custom`}
                    show={roleOrgGroupUser.accessRead}
                  />
                  <MenuItem
                    text="analytics general"
                    Icon={IconAnalytic}
                    to={`/org/user/list/edit/${row.userID}/analytic-general`}
                    show={roleOrgGroupUser.accessRead}
                  />
                  <MenuItem
                    text="assessments"
                    Icon={IconAssessment}
                    to={`/org/user/list/edit/${row.userID}/assessment-reports`}
                    show={roleOrgGroupUser.accessRead}
                  />

                  <MenuActivityLog to={`/org/user/list/activity-id/${row.userID}`} show />
                  <MenuItem text="auth history" Icon={IconAuthLogin} to={`/org/user/list/edit/${row.userID}/auth-history`} show={roleOrgGroupUser.accessRead} />
                  <MenuDelete to={`/org/user/list/edit/${row.userID}/delete`} show={roleOrgUser.accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
