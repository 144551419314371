import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { IconCalender, IconCaretDown } from '../icon/icon'
import datepicker from 'js-datepicker'
import { ButtonPrimary } from '../component/button'
import { useHistory } from 'react-router-dom'

export const Widget = ({ header, body, footer }) => {
	return (
		<div className='d-flex flex-column h-100'>
			<div className='m-1'>{header}</div>
			<div className='flex-fill m-1' style={{ minHeight: 0 }}>
				{body}
			</div>
			<div className='m-1'>{footer}</div>
		</div>
	)
}

export const ChartHeader = ({ Icon, text, Component }) => {
	return (
		<div className='d-flex m-1'>
			<div className='d-flex flex-column justify-content-center'>{Icon && <Icon size='2x' />}</div>
			<div className='flex-fill mx-3'>
				<div className='fs-4'>{text}</div>
				<div className='text-nowrap text-truncate fs-6'>{Component && <Component />}</div>
			</div>
		</div>
	)
}

export const DashboardHeader = ({ Icon, text, value }) => {
	return (
		<div className='d-flex m-1'>
			<div className='d-flex flex-column justify-content-center'>{Icon && <Icon size='2x' />}</div>
			<div className='flex-fill mx-3'>
				<div className='text-nowrap text-truncate fs-4'>{value}</div>
				<div className='fs-6'>{text}</div>
			</div>
		</div>
	)
}

export const CourseHeader = ({ Icon, text, value }) => {
	return (
		<div className='d-flex'>
			<div className='mx-1'>{Icon && <Icon size='2x' />}</div>
			<div className='text-nowrap text-truncate mx-1 fs-3'>{text}:</div>
			<div className='fs-3 mx-1'>{value}</div>
		</div>
	)
}

export const SelectRangeInterval = ({ intervals, start, end, interval, minDate, maxDate, onChangeStart, onChangeEnd, onChangeInterval }) => {
	return (
		<div className='d-flex flex-wrap'>
			<div className='flex-fill'>
				<SelectDate header='TO' value={start} minDate={minDate} maxDate={maxDate} onChange={onChangeStart} />
			</div>
			<div className='flex-fill'>
				<SelectDate header='FROM' value={end} minDate={minDate} maxDate={maxDate} onChange={onChangeEnd} />
			</div>
			<div className='flex-fill'>
				<SelectInterval header='INTERVAL' intervals={intervals} value={interval} onChange={onChangeInterval} />
			</div>
		</div>
	)
}

const SelectDate = ({ header, value, minDate, maxDate, onChange }) => {
	const ref = useRef()

	const [picker, updatePicker] = useState()

	const dateValue = useMemo(() => (value ? moment(value)._d : moment()._d), [value])

	const display = useMemo(() => moment(dateValue).format('MM/DD/YYYY'), [dateValue])

	const [isShowing, updateIsShowing] = useState(false)

	useEffect(() => {
		const newDatepicker = datepicker(ref.current, {
			showAllDates: true,
			onSelect: (instance, date) => {
				let format = moment(date).format('YYYY-MM-DD')
				onChange && onChange(format)
			},
			onShow: () => updateIsShowing(true),
			onHide: () => updateIsShowing(false)
		})

		updatePicker(newDatepicker)

		return () => newDatepicker.remove()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (picker && dateValue) {
			picker.setDate && picker.setDate(dateValue, true)
		}
	}, [picker, dateValue])

	useEffect(() => {
		if (picker && minDate) {
			picker.setMin && picker.setMin(moment(minDate)._d)
		}
	}, [picker, minDate])

	useEffect(() => {
		if (picker && maxDate) {
			picker.setMax && picker.setMax(moment(maxDate)._d)
		}
	}, [picker, maxDate])

	const handleClick = (e) => {
		e.stopPropagation()
		if (isShowing) picker.hide()
		else picker.show()
	}

	return (
		<div ref={ref} className='widget-select-range-interval-item border-bottom'>
			<div className='d-flex flex-wrap edxr-pointer' onClick={handleClick}>
				<div className='p-1'>
					{header} <IconCalender />
				</div>
				<div className='p-1'>{display}</div>
			</div>
		</div>
	)
}

const SelectInterval = ({ header, intervals, value, onChange }) => {
	const selected = useMemo(() => {
		if (!intervals) {
			return undefined
		}

		return intervals.find((i) => i.value === value).text
	}, [intervals, value])

	const handleClick = (interval) => onChange && onChange(interval.value)

	return (
		<div className='widget-select-range-interval-item border-bottom'>
			<div className='d-flex flex-wrap edxr-pointer' data-bs-toggle='dropdown'>
				<div className='p-1'>
					{header} <IconCaretDown size='lg' />
				</div>
				<div className='p-1'>{selected || 'Select'}</div>
				<div>
					<ul className='dropdown-menu'>
						{intervals &&
							intervals.map((interval, i) => (
								<li key={i} className={`dropdown-item ${interval.value === value ? 'active' : ''}`} onClick={() => handleClick(interval)}>
									{interval.text}
								</li>
							))}
					</ul>
				</div>
			</div>
		</div>
	)
}

export const Footer = ({ children }) => {
	return <div className='pt-2 text-wrap'>{children}</div>
}

export const FooterButton = ({ text, to }) => {
	const history = useHistory()
	const handleClick = () => history.push(to)
	return <ButtonPrimary text={text} small onClick={handleClick} />
}
