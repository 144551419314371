import React from 'react'
import { AppLinks } from '../../shared/legacy/app-links'
import { ButtonPrimary } from '../../shared/legacy/button'
import { Card } from '../../shared/card/card'

export const TradeShowResult = ({ state, onDismiss }) => {
  return (
    <React.Fragment>
      <h4 className="p-1">Account Created</h4>
      <div className="p-1">
        Use the pin below to sign up into the experience. This pin will expire in {state.form.pinExpire}, look for an invite email for full registration
      </div>
      <div className="p-1">
        <table className="table table-sm table-borderless">
          <tbody>
            <tr>
              <td className="text-end">Email</td>
              <td>
                <strong>
                  {state.form.email}
                </strong>
              </td>
            </tr>
            <tr>
              <td className="text-end">Pin</td>
              <td>
                <strong>
                  {state.form.pin}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ButtonPrimary
        style={{
          backgroundColor: state.form.colorButtonPrimaryBackground,
          color: state.form.colorButtonPrimaryText
        }}
        text="dismiss"
        onClick={onDismiss}
      />
      <Card className="mt-3" title="download links">
        <AppLinks />
      </Card>
    </React.Fragment>
  )
}
