import React from 'react'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useApiBlobFileTradeshow, useApiFormFileTradeshow } from '../../../../../../api-new/state/storage'
import { ImageEditor } from '../../../../../../shared/image-editor/image-editor'

export const TradeshowLogo = ({ identifier }) => {
  const apiFormFileTradeshow = useApiFormFileTradeshow()
  const apiBlobFileTradeshow = useApiBlobFileTradeshow()

  useLoad(async () => {
    if (identifier) {
      await apiBlobFileTradeshow.get(`${identifier}/stream-safe`)
    }
  }, identifier)

  const handleSave = async blob => {
    await apiFormFileTradeshow.postArg(`/${identifier}`, blob)
    await apiBlobFileTradeshow.get(`${identifier}/stream-safe`)
  }

  const handleDelete = async () => {
    await apiFormFileTradeshow.delete(identifier)
    apiBlobFileTradeshow.init()
  }

  return (
    <React.Fragment>
      <ImageEditor
        blob={apiBlobFileTradeshow.blob}
        height={28 * 5}
        cropWidthRatio={16}
        cropHeightRatio={9}
        fileSize={1024 * 500}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </React.Fragment>
  )
}
