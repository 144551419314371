import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Form, FormText, FormSelectListNum, FormCheckbox, FormSubmitBack } from '../../../../../shared/form/form'
import { Card } from '../../../../../shared/card/card'
import { IconEdit } from '../../../../../shared/icon/icon'
import { ScrollY } from '../../../../../shared/component/scroll'
import { useRoleSysOrganization } from '../../../../../role/hook/role-sys'
import { OrganizationLogo } from './organization-edit/organization-logo'
import { useApiFormOrganization } from '../../../../../api-new/state/content'

const limitUser = [1, 5, 10]
const limitCase = [3, 10, 20]

export function OrganizationEdit() {
  const { organizationID } = useParamsInt()

  const roleSysOrganization = useRoleSysOrganization()

  const apiFormOrganization = useApiFormOrganization()

  const handlePath = async () => {
    await apiFormOrganization.get(organizationID)
  }

  const handleSubmit = async data => await apiFormOrganization.put(data.id, data)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={apiFormOrganization.form.name} onPath={handlePath}>
        <ScrollY>
          <Card title="Organization Edit">
            <Form
              state={apiFormOrganization.form}
              submitting={apiFormOrganization.submitting}
              onSubmit={handleSubmit}
              disabled={!roleSysOrganization.accessUpdate}>
              <FormText label="Name" name="name" placeholder="organization name" required />
              <FormSelectListNum label="user limit" name="limitUser" nullText="Unlimited" state={limitUser} />
              <FormSelectListNum label="case limit" name="limitCase" nullText="Unlimited" state={limitCase} />
              <FormCheckbox label="feature analytics" name="featureAnalytic" />
              <FormCheckbox label="feature multiplayer" name="featureMultiplayer" />
              <FormCheckbox label="feature assessments" name="featureAssessment" />
              <FormCheckbox label="feature heatmap" name="featureHeatmap" />
              <FormCheckbox label="feature AR" name="featureAR" />
              <FormCheckbox label="feature tradeshow" name="featureTradeShow" />
              <FormCheckbox label="feature AI" name="featureAI" />
              <FormCheckbox label="access view public content" name="accessViewPublic" />
              <FormSubmitBack text="update organization" />
            </Form>
          </Card>

          <OrganizationLogo organizationID={organizationID} />
        </ScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
