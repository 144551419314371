import React, { useCallback, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { ButtonPrimary } from '../legacy/button'
import { useLogout } from '../hooks/logout'
import { ModalDialog, useModalActionID } from './modal-dialog'
import { useAuthContext } from '../../context/auth.context'

const useInterval = callback => {
  const [id, updateID] = useState(null)

  const start = useCallback(() => {
    let id = setInterval(() => callback(), 1000)

    updateID(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const stop = () => {
    if (id) {
      clearInterval(id)
      updateID(null)
    }
  }

  return [start, stop]
}

const useCountdown = (startValue, onComplete) => {
  const [value, updateValue] = useState(startValue)
  const [start, stop] = useInterval(() => updateValue(value => value - 1))

  useEffect(
    () => {
      if (value <= 0) {
        stop()
        onComplete()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  useEffect(() => {
    return () => stop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const startCountdown = () => {
    updateValue(startValue)
    start()
  }

  const stopCountdown = () => stop()

  return [startCountdown, stopCountdown, value]
}

export const Timeout = () => {
  const authContext = useAuthContext()

  const logout = useLogout()

  const [countdownStart, countdownStop, countdownValue] = useCountdown(parseInt(process.env.REACT_APP_TIMEOUT_DELAY_SECONDS), () => handleLogout())
  const [modalID, modalShow, modalHide] = useModalActionID()

  const handleIdle = () => {
    authContext.actions.refresh()

    pause()
    countdownStart()
    modalShow()
  }

  const { pause, resume } = useIdleTimer({
    timeout: parseInt(process.env.REACT_APP_TIMEOUT_IDLE_SECONDS * 1000),
    throttle: 1000,
    onIdle: handleIdle
  })

  const handleContinue = () => {
    countdownStop()
    resume()
    modalHide()
  }

  const handleLogout = () => logout()

  return (
    <React.Fragment>
      <ModalDialog target={modalID} header="Inactivity Warning" backdropStatic hideClose>
        <div className="py-2">
          Due to inactivity you will be signed out in {countdownValue}.
        </div>
        <div className="py-2">
          <ButtonPrimary text="Stay Signed In" onClick={handleContinue} />
          <ButtonPrimary text="OK" onClick={handleLogout} />
        </div>
      </ModalDialog>
    </React.Fragment>
  )
}
