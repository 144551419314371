import React from 'react'
import WidgetGroups from './user-login-edit/widget-group'
import WidgetNode from './user-login-edit/widget-node'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { DisplayRoleAdmin } from '../../../../../context/display-role-admin'
import { UserLoginDetails } from './user-login-edit/user-details'
import { UserRoles } from './user-login-edit/user-roles'
import { UserInvite } from './user-login-edit/user-invite'
import { HeatmapLayoutUser } from '../../../../../view/heatmap/layout-user'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { TileBody, TileColumn11112 } from '../../../../../shared/tile/tile-column-21111'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuActivityLog, MenuDelete, MenuItem } from '../../../../../shared/menu/menu'
import { useLocation } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { IconAnalytic, IconAssessment, IconAuthLogin, IconContent, IconEdit, IconGroup } from '../../../../../shared/icon/icon'
import { useApiFormUserLogin } from '../../../../../api-new/state/content'
import { useRoleOrgGroupUser, useRoleOrgNodeUser, useRoleOrgUser } from '../../../../../role/hook/role-org'

export const UserLoginEdit = () => {
  const { userID } = useParamsInt()
  const { pathname } = useLocation()

  const roleOrgUser = useRoleOrgUser()
  const roleOrgNodeUser = useRoleOrgNodeUser()
  const roleOrgGroupUser = useRoleOrgGroupUser()

  const apiFormUserLogin = useApiFormUserLogin()

  const handlePath = async () => await apiFormUserLogin.get(`${userID}`)

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconContent} text="contents" to={`/org/user/list/edit/${userID}/content`} show={roleOrgNodeUser.accessRead} />
      <MenuItem Icon={IconGroup} text="groups" to={`/org/user/list/edit/${userID}/group`} show={roleOrgGroupUser.accessRead} />
      <MenuItem text="analytics custom" Icon={IconAnalytic} to={`${pathname}/analytic-custom`} show={roleOrgGroupUser.accessRead} />
      <MenuItem text="analytics general" Icon={IconAnalytic} to={`${pathname}/analytic-general`} show={roleOrgGroupUser.accessRead} />
      <MenuItem text="assessments" Icon={IconAssessment} to={`${pathname}/assessment-reports`} show={roleOrgGroupUser.accessRead} />
      <MenuActivityLog to={`/org/user/list/activity-id/${userID}`} show />
      <MenuItem Icon={IconAuthLogin} text="auth history" to={`/org/user/list/edit/${userID}/auth-history`} show={roleOrgGroupUser.accessRead} />
      <MenuDelete to={`/org/user/list/edit/${userID}/delete`} show={roleOrgUser.accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={apiFormUserLogin.form.email} onPath={handlePath}>
        <CardFullHeightScrollY title={apiFormUserLogin.form.email} HeaderMenu={HeaderMenu}>
          <TileBody>
            <TileColumn11112>
              <UserLoginDetails userLogin={apiFormUserLogin.form} />

              <DisplayRoleAdmin>
                <UserInvite userLogin={apiFormUserLogin.form} onUpdate={handlePath} />
              </DisplayRoleAdmin>
            </TileColumn11112>

            <TileColumn11112>
              <UserRoles userID={userID} />

              <roleOrgGroupUser accessRead>
                <WidgetGroups userID={userID} />
              </roleOrgGroupUser>

              <roleOrgNodeUser accessRead>
                <WidgetNode userID={userID} />
              </roleOrgNodeUser>
            </TileColumn11112>
          </TileBody>
        </CardFullHeightScrollY>
      </BreadcrumbView>
      <HeatmapLayoutUser />
    </React.Fragment>
  )
}
