import React, { useMemo, useState } from 'react'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useApiBlobFileContent, useApiFormFileContent } from '../../../../../../api-new/state/storage'
import { useApiFormAssetThumbnail, useApiListAssetThumbnail } from '../../../../../../api-new/state/content'
import { useDataContext } from '../../data-context'
import { LoginSysAdmin } from '../../../../../../role/components/login-sys-admin'
import { ImageEditor } from '../../../../../../shared/image-editor/image-editor'

export const ThumnailAsset = ({ nodeID }) => {
  const apiListAssetThumbnail = useApiListAssetThumbnail()
  const apiFormAssetThumbnail = useApiFormAssetThumbnail()

  const apiFormFileContent = useApiFormFileContent()
  const apiBlobFileContent = useApiBlobFileContent()

  const dataContext = useDataContext()

  const [fileExists, updateFileExists] = useState(false)

  useLoad(async () => {
    let thumbnails = await apiListAssetThumbnail.get(`${nodeID}/node`)
    if (thumbnails.length === 0) {
      let thumbnail = await apiListAssetThumbnail.post({ nodeID: nodeID, name: 'thumbnail' })
      dataContext.thumbnailInsert(thumbnail)
    }
  }, nodeID)

  const asset = useMemo(
    () => {
      if (apiListAssetThumbnail.items.length === 0) return null
      else return apiListAssetThumbnail.items[0]
    },
    [apiListAssetThumbnail.items]
  )

  useLoad(
    async () => {
      if (asset) {
        let file = await apiBlobFileContent.get(`${asset.key}/stream-safe`)

        if (file.size) {
          updateFileExists(true)
        }
      }
    },
    [asset]
  )

  const handleSave = async blob => {
    if (asset) {
      await apiFormAssetThumbnail.put(`${asset.id}/custom`)
      await apiFormFileContent.postArg(`/${asset.key}`, blob)
      await apiBlobFileContent.get(`${asset.key}/stream-safe`)
      dataContext.thumbnailInsert(asset)
    }
  }

  const handleDelete = async () => {
    await apiFormFileContent.delete(asset.key)
    await apiFormAssetThumbnail.delete(`${asset.id}`)
    apiBlobFileContent.init()
    dataContext.thumbnailRemove(asset.id)
  }

  return (
    <React.Fragment>
      {!fileExists && <div>NO FILE FOUND</div>}

      <LoginSysAdmin>
        {asset && asset.key}
      </LoginSysAdmin>

      <ImageEditor
        blob={apiBlobFileContent.blob}
        // width={100 * 5}
        height={50 * 5}
        cropWidthRatio={2}
        cropHeightRatio={1}
        fileSize={1024 * 500}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </React.Fragment>
  )
}
