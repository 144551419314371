import { useApiForm } from '../api-form'
import { useApiList } from '../api-list'
import { useApiWebsocket } from '../api-websocket'
import { urlArgs } from '../state'

export const useApiFormDashboard = args => useApiForm(process.env.REACT_APP_API_AI, `api/ddd/dashboard${urlArgs(args)}`)

export const useApiListDocument = args => useApiList(process.env.REACT_APP_API_AI, `api/ddd/document${urlArgs(args)}`)
export const useApiFormDocument = args => useApiForm(process.env.REACT_APP_API_AI, `api/ddd/document${urlArgs(args)}`)

export const useApiFormEmbedding = args => useApiForm(process.env.REACT_APP_API_AI, `api/ddd/embedding${urlArgs(args)}`)

export const useApiFormCompletion = args => useApiForm(process.env.REACT_APP_API_AI, `api/ddd/completion${urlArgs(args)}`)

export const useApiListConfig = args => useApiList(process.env.REACT_APP_API_AI, `api/ddd/config${urlArgs(args)}`)
export const useApiFormConfig = args => useApiForm(process.env.REACT_APP_API_AI, `api/ddd/config${urlArgs(args)}`)

//////////////////////////////////////////
//WEB SOCKET
//////////////////////////////////////////

export const useEmbeddingNodeProgress = (onProgress, onComplete) => {
  const [connect] = useApiWebsocket(process.env.REACT_APP_API_AI, 'api/ddd/embedding/ws/node')

  const handleOpen = () => {}

  const handleMessage = progress => onProgress && onProgress(progress)

  const handleClose = () => onComplete && onComplete()

  const handleError = () => {}

  const start = async value => {
    const [init, send] = connect(handleOpen, handleMessage, handleClose, handleError)

    await init()
    await send(value)
  }

  return [start]
}
