import React, { useMemo, useState } from 'react'
import { ButtonPrimary, ButtonSecondary } from '../shared/legacy/button'
import { TermsAccept } from '../shared/legacy/legal/termsAccept'
import { useQueryParam } from '../shared/hooks/location'
import { CardFullHeightScrollY } from '../shared/card/card-full-height-scroll-y'
import { useApiFormAuthProvider } from '../api-new/state/auth'
import { AlertError } from '../shared/alert/alert-error'
import { useLoad } from '../shared/hooks/load'

export const OpenRegister = () => {
  const token = useQueryParam('token')
  const [termsConditions, updateTermsConditions] = useState(false)

  const [error, updateError] = useState()

  const apiFormAuthProvider = useApiFormAuthProvider()
  const apiFormAuthProviderDetails = useApiFormAuthProvider()

  useLoad(async () => await apiFormAuthProviderDetails.get(`${token}/details`), [])

  const handleSubmit = async () => {
    try {
      await apiFormAuthProvider.get(`${token}/register`)

      let url = new URL(`${process.env.REACT_APP_SITE}/open/organization`)
      url.searchParams.set('token', token)
      window.location = url.href
    } catch (error) {
      debugger
      updateError(error)
    }
  }

  const handleCheck = termsConditions => updateTermsConditions(termsConditions)

  const handleCancel = async () => (window.location = apiFormAuthProviderDetails.redirectCancelURL)

  const title = useMemo(() => `Register account ${apiFormAuthProviderDetails.form.email} to ${apiFormAuthProviderDetails.form.provider}`, [
    apiFormAuthProviderDetails
  ])

  return (
    <React.Fragment>
      <CardFullHeightScrollY title={title}>
        <AlertError error={apiFormAuthProvider.error} />
        <AlertError error={error} />

        <div className="py-2">
          You are about to register a new EducationXR account and link it to this provider. Until you disconnect this account it will be able to be used to
          access to your EducationXR account. Please make sure that you trust this provider before linking your account.
        </div>

        <div className="py-2">
          <TermsAccept state={termsConditions} onCheck={handleCheck} />
        </div>

        <div className="py-2">
          <ButtonSecondary text="cancel" state={termsConditions} onClick={handleCancel} />
          <ButtonPrimary text="register account" disabled={!termsConditions} onClick={handleSubmit} />
        </div>
      </CardFullHeightScrollY>
    </React.Fragment>
  )
}
