import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { DisplayAuth } from '../context/display-auth'
import { Footer } from './component/footer'
import { SidebarLeft } from './component/sidebar-left'
import { RouteAdmin } from './route/route-admin'
import { RouteDownload } from './route/route-download'
import { RoutePublic } from './route/route-public'
import { RouteRedirect } from './route/route-redirect'
import { RouteOpen } from './route/route-open'
import { RouteAuth } from './route/route-auth'
import { RouteDoc } from './route/route-doc'
import { SubHeader } from './component/subheader'
import { AuthHeader, UnAuthHeader } from './component/header'
import { DisplayUnauth } from '../context/display-unauth'
import { AuthRedirect } from './component/auth-redirect'
import { NotificationProvider } from '../context/notification/context'
import { useAuthContext } from '../context/auth.context'

export const PageLayout = () => {
  const location = useLocation()
  const authContext = useAuthContext()

  //check auth on load/reload
  useEffect(() => {
    if (authContext.state.isAuth) {
      authContext.actions.refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="d-flex h-100">
      <div className="flex-shrink">
        <DisplayAuth>
          <div className="d-none d-lg-block h-100" style={{ width: 260 }}>
            <SidebarLeft />
          </div>
        </DisplayAuth>
      </div>
      <div className="flex-fill d-flex flex-column h-100" style={{ minWidth: 0 }}>
        <div className="flex-shirink">
          <DisplayUnauth>
            <UnAuthHeader />
          </DisplayUnauth>
          <DisplayAuth>
            <AuthHeader />
            <SubHeader />
          </DisplayAuth>
        </div>
        <div className="flex-fill overflow-hidden p-2">
          <NotificationProvider>
            <DisplayUnauth>
              <Switch>
                {/* <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} /> */}

                <Route path="/public" component={RoutePublic} />
                <Route path="/download" component={RouteDownload} />
                <Route path="/redirect" component={RouteRedirect} />
                <Route path="/open" component={RouteOpen} />

                <Route path="/auth" component={RouteAuth} />
                <Route path="/" component={AuthRedirect} />
              </Switch>
            </DisplayUnauth>

            <DisplayAuth>
              <Switch>
                {/* <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} /> */}

                <Route path="/public" component={RoutePublic} />
                <Route path="/download" component={RouteDownload} />
                <Route path="/redirect" component={RouteRedirect} />
                <Route path="/open" component={RouteOpen} />

                <Route path="/auth" component={RouteAuth} />
                <Route path="/doc" component={RouteDoc} />
                <Route path="/" component={RouteAdmin} />
              </Switch>
            </DisplayAuth>
          </NotificationProvider>
        </div>
        <div className="flex-shrink">
          <Footer />
        </div>
      </div>
    </div>
  )
}
