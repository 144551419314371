import React, { useMemo } from 'react'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useAdminNodeContext } from './admin-node-context'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Menu, MenuDelete, MenuItem } from '../../../../../shared/menu/menu'
import { Form, FormSubmitBack, FormText } from '../../../../../shared/form/form'
import { useApiFormNode } from '../../../../../api-new/state/content'
import { NodeAccess } from './form/node-access'
import { IconEdit, IconEnter } from '../../../../../shared/icon/icon'
import { FormJsonEditorStr } from '../../../../../shared/form/form-control/form-json-editor-str'

export const AdminNodeEdit = ({ onChildren, onAssets, onDelete }) => {
	const { nodeID } = useParamsInt()

	const adminNodeContext = useAdminNodeContext()

	const apiFormNode = useApiFormNode()

	const handleMatch = () => apiFormNode.get(nodeID)

	const handleChildren = () => onChildren(apiFormNode.form)
	const handleAssets = () => onAssets(apiFormNode.form)
	const handleDelete = () => onDelete(apiFormNode.form)

	const handleSubmit = async (data) => {
		let node = await apiFormNode.put(data.id, data)
		adminNodeContext.nodeUpdate(node)
	}

	const headerMenu = () => (
		<Menu>
			<MenuItem Icon={IconEnter} text='children' onClick={handleChildren} show pin />
			<MenuItem Icon={IconEnter} text='assets' onClick={handleAssets} show />
			<MenuDelete onClick={handleDelete} show />
		</Menu>
	)

	return (
		<React.Fragment>
			<BreadcrumbView Icon={IconEdit} text='edit' onMatch={handleMatch}>
				<CardFullHeightScrollY title={apiFormNode.form.name} HeaderMenu={headerMenu}>
					<Form state={apiFormNode.form} onSubmit={handleSubmit}>
						<FormText label='name' name='name' required />
						<NodeAccess />
						<FormJsonEditorStr label='metadata' name='metadata' />
						<FormSubmitBack />
					</Form>
				</CardFullHeightScrollY>
			</BreadcrumbView>
		</React.Fragment>
	)
}

// const initial = {
// 	string: 'string1',
// 	int: 1,
// 	obj: {
// 		string: 'string2',
// 		int: 2
// 	},
// 	arr: [
// 		{
// 			string: 'string3',
// 			int: 3
// 		},
// 		{
// 			string: 'string4',
// 			int: 4
// 		},
// 		{
// 			string: 'string5',
// 			int: 5,
// 			arr: [
// 				{
// 					string: 'string6',
// 					int: 6
// 				}
// 			]
// 		}
// 	]
// }

// const Metadata = () => {
// 	const [metadata, updateMetadata] = useState(initial)

// 	const handleChange = (value) => {
// 		// debugger
// 		updateMetadata(value)
// 	}

// 	return <TypeObject objValue={metadata} onChange={handleChange} />
// }

const Type = ({ objKey, objValue, onChange }) => {
	const objType = useMemo(() => {
		let type = typeof objValue
		if (type === 'object') return Array.isArray(objValue) ? 'array' : 'object'
		else return type
	}, [objValue])

	return (
		<React.Fragment>
			{objType === 'string' && <TypeString objKey={objKey} objValue={objValue} onChange={onChange} />}
			{objType === 'number' && <TypeNumber objKey={objKey} objValue={objValue} onChange={onChange} />}
			{objType === 'object' && <TypeObject objKey={objKey} objValue={objValue} />}
			{/* {objType === 'array' && <TypeArray value={value} />} */}
		</React.Fragment>
	)
}

const TypeString = ({ objKey, objValue, onChange }) => {
	const handleChange = (e) => onChange(objKey, e.target.value)
	return <input value={objValue} onChange={handleChange} />
}

const TypeNumber = ({ objKey, objValue, onChange }) => {
	const handleChange = (e) => onChange(objKey, e.target.value)
	return <input value={objValue} onChange={handleChange} />
}

const TypeObject = ({ objKey, objValue, onChange }) => {
	const objKeys = useMemo(() => Object.keys(objValue), [objValue])

	const handleChange = (key, value) => {
		objValue[key] = value
		let newObjValue = Object.assign({}, objValue)
		onChange(newObjValue)
	}

	return (
		<div className='container'>
			{objKeys.map((objKey, i) => (
				<div className='row border-bottom' key={i}>
					<div className='col-3'>{objKey}</div>
					<div className='col-9'>
						<Type jsonKey={objKey} objValue={objValue[objKey]} onChange={handleChange} />
					</div>
				</div>
			))}
		</div>
	)
}

// const TypeArray = ({ value }) => {
// 	return (
// 		<div>
// 			{value.map((value, i) => (
// 				<TypeObject key={i} value={value} />
// 			))}
// 		</div>
// 	)
// }
