import React, { useRef } from 'react'
import { InputMessage } from './input'
import { Query } from './query'
import { Item } from './item'

export const ScreenConversation = ({ items, query, submitting, onSubmit }) => {
  const ref = useRef()

  const handleSubmit = async content => {
    setTimeout(() => ref.current.scrollIntoView(false), 1)
    await onSubmit(content)
    setTimeout(() => ref.current.scrollIntoView(false), 1)
  }

  return (
    <React.Fragment>
      <div className="flex-fill" style={{ minHeight: 0 }}>
        <div className="border rounded p-2 w-100 h-100 overflow-y-scroll">
          <div>
            <div ref={ref}>
              {items && items.map((item, i) => <Item key={i} item={item} />)}
              <Query value={query} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <InputMessage submitting={submitting} onSubmit={handleSubmit} />
      </div>
    </React.Fragment>
  )
}
