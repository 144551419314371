import { useApiForm } from '../api-form'
import { useApiList } from '../api-list'
import { urlArgs } from '../state'

export const useApiListProvider = args => useApiList(process.env.REACT_APP_API_AUTH, `api/v1/provider${urlArgs(args)}`)
export const useApiFormProvider = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/provider${urlArgs(args)}`)
export const useApiListProviderOrganinization = args => useApiList(process.env.REACT_APP_API_AUTH, `api/v1/provider-organization${urlArgs(args)}`)
export const useApiFormProviderOrganization = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/provider-organization${urlArgs(args)}`)
export const useApiFormAuthEmail = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/auth-email${urlArgs(args)}`)
export const useApiFormAuthPin = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/auth-pin${urlArgs(args)}`)
export const useApiFormAuthRefresh = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/auth-refresh${urlArgs(args)}`)
export const useApiListAuthOrganization = args => useApiList(process.env.REACT_APP_API_AUTH, `api/v1/auth-organization${urlArgs(args)}`)
export const useApiFormAuthLogout = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/auth-logout${urlArgs(args)}`)
export const useApiFormPasswordUpdate = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/password-update${urlArgs(args)}`)
export const useApiFormAuthDevice = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/auth-device${urlArgs(args)}`)
export const useApiFormAuthPassiveLogin = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/auth-passive-login${urlArgs(args)}`)
export const useApiListAuthProviderLink = args => useApiList(process.env.REACT_APP_API_AUTH, `api/v1/provider-link${urlArgs(args)}`)
export const useApiListAuthProviderLogin = args => useApiList(process.env.REACT_APP_API_AUTH, `api/v1/auth-provider-login${urlArgs(args)}`)
export const useApiListAuthProvider = args => useApiList(process.env.REACT_APP_API_AUTH, `api/v1/auth-provider${urlArgs(args)}`)
export const useApiFormAuthProvider = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/auth-provider${urlArgs(args)}`)
export const useApiFormAuthPasswordRecover = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/password-recover${urlArgs(args)}`)
export const useApiFormLoginSettings = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/login-settings${urlArgs(args)}`)
export const useApiListAuthStatus = args => useApiList(process.env.REACT_APP_API_AUTH, `api/v1/auth-status${urlArgs(args)}`)
export const useApiListLoginProvider = args => useApiList(process.env.REACT_APP_API_AUTH, `api/v1/login-provider${urlArgs(args)}`)

export const useApiFormAuthImpersonate = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/auth-impersonate${urlArgs(args)}`)

export const useApiListAuthHistory = args => useApiList(process.env.REACT_APP_API_AUTH, `api/v1/auth-history${urlArgs(args)}`)
export const useApiFormAuthHistory = args => useApiForm(process.env.REACT_APP_API_AUTH, `api/v1/auth-history${urlArgs(args)}`)
