import React from 'react'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useApiBlobFilePublic, useApiFormFilePublic } from '../../../../../../api-new/state/storage'
import { ImageEditor } from '../../../../../../shared/image-editor/image-editor'

export const OrganizationLogo = ({ organizationID }) => {
  const apiFormFilePublic = useApiFormFilePublic()
  const apiBlobFilePublic = useApiBlobFilePublic()

  useLoad(async () => {
    await apiBlobFilePublic.get(`${organizationID}/stream-safe`)
  }, organizationID)

  const handleSave = async blob => {
    await apiFormFilePublic.postArg(`/${organizationID}`, blob)
    await apiBlobFilePublic.get(`${organizationID}/stream-safe`)
  }

  const handleDelete = async () => {
    await apiFormFilePublic.delete(organizationID)
    apiBlobFilePublic.init()
  }

  return (
    <React.Fragment>
      <ImageEditor
        blob={apiBlobFilePublic.blob}
        width1={120 * 5}
        height={28 * 5}
        cropWidthRatio={13}
        cropHeightRatio={3}
        fileSize={1024 * 500}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </React.Fragment>
  )
}
