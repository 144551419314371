import axios from 'axios'
import { getAccessToken, getRefreshToken, setAccessToken } from '../storage/auth'

const checkAccessTokenExp = async accessToken => {
  if (accessToken) {
    let token = accessToken.split('.')[1]
    let claims = atob(token)

    let claimsObj = JSON.parse(claims)
    let exp = new Date(claimsObj.exp * 1000)
    let now = new Date()

    if (now > exp) {
      let refreshToken = getRefreshToken()

      let endpoint = `${process.env.REACT_APP_API_AUTH}/api/v1/auth-refresh/${refreshToken}`

      let result = await axios.get(endpoint)

      if (result.status === 200) {
        accessToken = result.data.accessToken
        setAccessToken(accessToken)
      }
    }
  }

  return accessToken
}

const getConfig = async (config, contentType = 'application/json') => {
  config = config || {}

  let headers = {}

  let accessToken = getAccessToken()

  accessToken = await checkAccessTokenExp(accessToken)

  if (accessToken) {
    headers['Authorization'] = accessToken
  }

  headers['Content-Type'] = contentType
  config['headers'] = headers

  return config
}

const getConfigBlob = () => {
  let config = {
    responseType: 'blob'
  }

  let headers = {}

  const accessToken = getAccessToken()

  if (accessToken) {
    headers['Authorization'] = accessToken
  }

  config['headers'] = headers

  return config
}

// function sleep(ms) {
//   console.log('DELAYING API FOR TESTING MS: ', ms)
//   return new Promise(resolve => setTimeout(resolve, ms))
// }

function checkAuthRedirect(error) {
  if (error.response.status === 401) {
    window.location = '/auth/email'
  }

  // if (error.message === 'Request failed with status code 401') {
  //   window.location = '/auth/email'
  // }
}

function checkError(error, fnReject) {
  if (error.response) {
    if (error.response.data.error) {
      fnReject({ error: error.response.data.error, status: error.response.status })
    } else if (error.response.data) {
      fnReject({ error: error.response.data, status: error.response.status })
    } else {
      fnReject({ error: 'an error occured' })
    }
  } else if (error.request) {
    fnReject(error.request)
  } else {
    fnReject({ error: 'an error occured' })
  }
}

export function getBlobAsync(server, endpoint) {
  return new Promise((resolve, reject) => {
    const url = `${server}/${endpoint}`
    let config = getConfigBlob()

    axios
      .get(url, config)
      .then(async response => {
        // if (process.env.REACT_APP_API_DELAY) {
        //   await sleep(process.env.REACT_APP_API_DELAY)
        // }

        resolve(response)
      })
      .catch(error => {
        console.error(error)
        checkError(error, reject)
      })
  })
}

export function getAsync(server, endpoint) {
  return new Promise(async (resolve, reject) => {
    const url = `${server}/${endpoint}`

    let config = await getConfig()

    axios
      .get(url, config)
      .then(async response => {
        // if (process.env.REACT_APP_API_DELAY) {
        //   await sleep(process.env.REACT_APP_API_DELAY)
        // }

        resolve(response)
      })
      .catch(error => {
        console.error(error)
        checkAuthRedirect(error)
        checkError(error, reject)
      })
  })
}

export function postAsync(server, endpoint, data) {
  return new Promise(async (resolve, reject) => {
    const url = `${server}/${endpoint}`

    let config = await getConfig()

    axios
      .post(url, data, config)
      .then(async response => {
        // if (process.env.REACT_APP_API_DELAY) {
        //   await sleep(process.env.REACT_APP_API_DELAY)
        // }

        resolve(response)
      })
      .catch(error => {
        console.error(error)
        checkAuthRedirect(error)
        checkError(error, reject)
      })
  })
}

export function putAsync(server, endpoint, data) {
  return new Promise(async (resolve, reject) => {
    const url = `${server}/${endpoint}`

    let config = await getConfig()

    axios
      .put(url, data, config)
      .then(async response => {
        // if (process.env.REACT_APP_API_DELAY) {
        //   await sleep(process.env.REACT_APP_API_DELAY)
        // }

        resolve(response)
      })
      .catch(error => {
        console.error(error)
        checkAuthRedirect(error)
        checkError(error, reject)
      })
  })
}

export function deleteAsync(server, endpoint) {
  return new Promise(async (resolve, reject) => {
    const url = `${server}/${endpoint}`

    let config = await getConfig()

    axios
      .delete(url, config)
      .then(async response => {
        // if (process.env.REACT_APP_API_DELAY) {
        //   await sleep(process.env.REACT_APP_API_DELAY)
        // }

        resolve(response)
      })
      .catch(error => {
        console.error(error)
        checkAuthRedirect(error)
        checkError(error, reject)
      })
  })
}
