import React, { useRef } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
// import { BadgePrimary, BadgeSuccess, BadgeWarning } from '../../../../../shared/component/badge'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnDate, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { IconAuthLogin } from '../../../../../shared/icon/icon'
import { useApiListAuthHistory } from '../../../../../api-new/state/auth'
import { useParamsInt } from '../../../../../shared/hooks/location'

export const UserLoginAuthHistory = () => {
  const { userID } = useParamsInt()

  const apiListAuthHistory = useApiListAuthHistory()

  // const roleOrgUser = useRoleOrgUser()
  // const roleOrgNodeUser = useRoleOrgNodeUser()
  // const roleOrgGroupUser = useRoleOrgGroupUser()

  const handleReady = async () => await apiListAuthHistory.get(`${userID}/user`)

  const tableRef = useRef()
  // const handleExportCsv = () => tableRef.current.exportCsv()
  // const handleExportExcel = () => tableRef.current.exportExcel()
  // const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      {/* <MenuAdd text="add users" to="/org/user/list/add" show={roleOrgUser.accessCreate} />
      <MenuRecycle to="/org/user/list/recycle" show={roleOrgUser.accessDelete} />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to="/org/user/list/activity-all" show /> */}
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAuthLogin} text="Auth History">
        <CardFullHeightScrollY title="Auth History" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListAuthHistory} onReady={handleReady}>
            <ColumnDate field="createdAt" sortable />
            <Column field="authType" sortable />
            <Column field="ipAddress" sortable />
            <Column field="ipLocation" sortable />
            <Column field="deviceType" sortable />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
