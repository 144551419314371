import { useApiBlob } from '../api-blob'
import { useApiForm } from '../api-form'
import { urlArgs } from '../state'

export const useApiFormChunkUploadContent = args => useApiForm(process.env.REACT_APP_API_STORAGE, `api/ddd/chunk-upload-content${urlArgs(args)}`)

export const useApiFormFileContent = args => useApiForm(process.env.REACT_APP_API_STORAGE, `api/ddd/file-content${urlArgs(args)}`)
export const useApiBlobFileContent = args => useApiBlob(process.env.REACT_APP_API_STORAGE, `api/ddd/file-content${urlArgs(args)}`)

export const useApiFormFilePublic = args => useApiForm(process.env.REACT_APP_API_STORAGE, `api/ddd/file-public${urlArgs(args)}`)
export const useApiBlobFilePublic = args => useApiBlob(process.env.REACT_APP_API_STORAGE, `api/ddd/file-public${urlArgs(args)}`)

export const useApiFormFileTradeshow = args => useApiForm(process.env.REACT_APP_API_STORAGE, `api/ddd/file-tradeshow${urlArgs(args)}`)
export const useApiBlobFileTradeshow = args => useApiBlob(process.env.REACT_APP_API_STORAGE, `api/ddd/file-tradeshow${urlArgs(args)}`)
