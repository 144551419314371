import React from 'react'
import { useAuthContext } from '../../context/auth.context'

export function FeatureTradeShow({ children }) {
  const authContext = useAuthContext()

  return (
    <React.Fragment>
      {authContext.state.jwtClaims.featureTradeShow && children}
    </React.Fragment>
  )
}

export function FeatureAI({ children }) {
  const authContext = useAuthContext()

  return (
    <React.Fragment>
      {authContext.state.featureAI && children}
    </React.Fragment>
  )
}

export const useFeatureTradeShow = () => {
  const authContext = useAuthContext()
  return authContext.state && authContext.state.featureTradeShow
}

export const useFeatureAI = () => {
  const authContext = useAuthContext()
  return authContext.state && authContext.state.featureAI
}
