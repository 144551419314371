import React from 'react'
import { Card } from '../../../../../../shared/card/card'

export const UserLoginDetails = ({ userLogin }) => {
  return (
    <React.Fragment>
      <Card title="user details">
        <div className="mb-3">
          <label className="form-label">Email</label>
          <div className="form-control">
            {userLogin.email}
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">First Name</label>
          <div className="form-control">
            {userLogin.firstName}
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Last Name</label>
          <div className="form-control">
            {userLogin.lastName}
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}
