import React, { useMemo, useState } from 'react'
import { Captcha } from '../../shared/legacy/captcha'
import { Form, FormEmail, FormText, FormHiddenText, useFormDisable } from '../../shared/form/form'
import { useFormContext } from 'react-hook-form'
import { ButtonSecondary } from '../../shared/legacy/button'

const FormReset = ({ style }) => {
  const { reset } = useFormContext()
  const handleClear = () => reset()
  return <ButtonSecondary style={style} text="clear" type="reset" onClick={handleClear} />
}

export const FormSignUp = ({ style, disable }) => {
  const { formState } = useFormContext()

  const formDisabled = useFormDisable()
  const hasErrors = useMemo(() => Object.keys(formState.errors).length > 0, [formState])
  const isDirty = useMemo(() => formState.isDirty, [formState])

  return <input className="btn btn-primary" style={style} value="Sign up" type="submit" disabled={!isDirty || hasErrors || formDisabled || disable} />
}

export const TradeShowRegisterForm = ({ identifier, actions, state, data, onSignup }) => {
  const [verified, updateVerified] = useState(false)

  const email = useMemo(() => (data ? data.email : undefined), [data])
  const firstName = useMemo(() => (data ? data.firstName : undefined), [data])
  const lastName = useMemo(() => (data ? data.lastName : undefined), [data])

  const handleVerified = verified => updateVerified(verified)

  const handleSubmit = async data => {
    onSignup && onSignup(data)
  }

  return (
    <React.Fragment>
      <Form state={data} onSubmit={handleSubmit}>
        <FormHiddenText name="identifier" value={identifier} />
        <FormEmail label="email" name="email" value={email} required />
        <FormText label="first name" name="firstName" value={firstName} required />
        <FormText label="last name" name="lastName" value={lastName} required />

        <FormReset
          style={{
            backgroundColor: state.form.colorButtonSecondaryBackground,
            color: state.form.colorButtonSecondaryText
          }}
        />
        <FormSignUp
          style={{
            backgroundColor: state.form.colorButtonPrimaryBackground,
            color: state.form.colorButtonPrimaryText
          }}
          disable={!verified}
        />
      </Form>

      <Captcha onVerify={handleVerified} />
    </React.Fragment>
  )
}
