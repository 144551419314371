import React from 'react'
import { useParamsInt } from '../../hooks/location'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { IconEdit } from '../../icon/icon'
import { CardFullHeightScrollY } from '../../card/card-full-height-scroll-y'
import { Card } from '../../card/card'
import { useApiFormLogEventOrg } from '../../../api-new/state/analytics'
import moment from 'moment'
import { BodyDiff } from './common/json-diff'

export function LogEventDetails() {
	const { logEventID } = useParamsInt()

	const apiFormLogEventOrg = useApiFormLogEventOrg()

	const handlePath = async () => await apiFormLogEventOrg.get(`${logEventID}/details`)

	return (
		<React.Fragment>
			<BreadcrumbView Icon={IconEdit} text='details' onPath={handlePath}>
				<CardFullHeightScrollY title='event'>
					<Display title='details'>
						<DateTime label='occured' value={apiFormLogEventOrg.form.createdAt} />
						<Text label='sessionID' value={apiFormLogEventOrg.form.sessionID} />
						<Text label='email' value={apiFormLogEventOrg.form.email} />
						<Text label='path' value={apiFormLogEventOrg.form.path} />
						<Text label='method' value={apiFormLogEventOrg.form.method} />
						<Text label='ipAddress' value={apiFormLogEventOrg.form.ipAddress} />
					</Display>

					<BodyDiff current={apiFormLogEventOrg.form.bodyCurrent} previous={apiFormLogEventOrg.form.bodyPrevious} />
				</CardFullHeightScrollY>
			</BreadcrumbView>
		</React.Fragment>
	)
}

const Display = ({ children, title }) => {
	return <Card title={title}>{children}</Card>
}

const Text = ({ label, value }) => {
	return (
		<div className='p-2'>
			<label className='form-label text-capitalize'>{label}</label>
			<input className='form-control' value={value} readOnly />
		</div>
	)
}

const DateTime = ({ label, value }) => {
	return (
		<div className='p-2'>
			<label className='form-label text-capitalize'>{label}</label>
			<input className='form-control' value={moment(value).format('MM/DD/YYYY hh:mm:ss a')} readOnly />
		</div>
	)
}
