import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconAiDocument, IconFile, IconSettings } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Route, useRouteMatch, useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { AiDocumentList } from './ai-document-list'
import { AiSettings } from './ai-settings'
import { AiChat } from './ai-chat/ai-chat'
import { useApiFormDashboard } from '../../../../../../api-new/state/ai'
import { ButtonPrimary } from '../../../../../../shared/component/button'

export const AiDashboard = ({ nodeID }) => {
  const { url } = useRouteMatch()

  const apiFormDashboard = useApiFormDashboard()

  const handleMatch = () => apiFormDashboard.get(`${nodeID}/node`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAiDocument} text="ai" onMatch={handleMatch}>
        <CardFullHeightScrollY title="AI Dashboard">
          <Dashboard>
            <DashboardItem Icon={IconFile} title="Documents" value={apiFormDashboard.form.documentCount}>
              <div className="my-3">
                <DashboardButtonLink text="view documents" to={`${url}/documents`} />
              </div>
              <div>
                Embeddings: <span>{apiFormDashboard.form.embeddingCount}</span>
              </div>
            </DashboardItem>
            <DashboardItem Icon={IconSettings} title="Settings">
              <div className="my-3">
                <DashboardButtonLink text="edit settings" to={`${url}/settings`} />
              </div>
              <div>
                Default settings: {apiFormDashboard.form.configDefault ? <span>Yes</span> : <span>No</span>}
              </div>
              <div>
                Chat Model: <span>{apiFormDashboard.form.configChatModel}</span>
              </div>
              <div>
                Reference Documents: {apiFormDashboard.form.configReferenceDocuments ? <span>Yes</span> : <span>No</span>}
              </div>
              <div>
                Tempature: <FormatTempature value={apiFormDashboard.form.configTempature} />
              </div>
            </DashboardItem>
            <DashboardItem Icon={IconAiDocument} title="Chat">
              <div className="my-3">
                <DashboardButtonLink text="start chat" to={`${url}/chat`} />
              </div>
            </DashboardItem>
          </Dashboard>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/documents`}>
        <AiDocumentList nodeID={nodeID} />
      </Route>

      <Route path={`${url}/settings`}>
        <AiSettings nodeID={nodeID} />
      </Route>

      <Route path={`${url}/chat`}>
        <AiChat nodeID={nodeID} />
      </Route>
    </React.Fragment>
  )
}

const DashboardButtonLink = ({ text, to }) => {
  const history = useHistory()
  const handleClick = () => history.push(to)
  return (
    <ButtonPrimary small onClick={handleClick}>
      {text}
    </ButtonPrimary>
  )
}

const FormatTempature = ({ value }) =>
  <span>
    {value ? value.toFixed(1) : 0}
  </span>

const DashboardItem = ({ children, Icon, title, value }) => {
  return (
    <React.Fragment>
      <div className="col-12 col-md-6 col-xxl-4 p-2">
        <div className="card" style={{ height: 300 }}>
          <div className="card-body">
            <div className="card-title text-nowrap text-truncate">
              {Icon && <Icon size="xl" />}
              {title &&
                <span className="h4 ms-2">
                  {title}
                </span>}
              {value &&
                <span className="h4">
                  :<span className="ms-2">{value}</span>
                </span>}
            </div>
            <div className="card-text">
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Dashboard = ({ children }) => {
  return (
    <React.Fragment>
      <div className="row g-0">
        {children}
      </div>
    </React.Fragment>
  )
}
