import React, { useMemo } from 'react'
import { LoginSysAdmin } from '../../../../../../role/components/login-sys-admin'
import { Card } from '../../../../../../shared/card/card'
import { useNotificationContext } from '../../../../../../context/notification/context'
import { ButtonPrimaryConfirm, ButtonPrimaryDelay } from '../../../../../../shared/component/button'
import { IconCheck, IconEmail } from '../../../../../../shared/icon/icon'
import moment from 'moment'
import { useApiFormUserInvite } from '../../../../../../api-new/state/content'

const ItemReadOnly = ({ label, value }) => {
  return (
    <React.Fragment>
      <div className="form-group">
        <label className="text-capitalize">
          {label}
        </label>
        <div className="form-control text-nowrap text-truncate">
          {value}
        </div>
      </div>
    </React.Fragment>
  )
}

export const UserInvite = ({ userLogin, onUpdate }) => {
  const apiFormUserInvite = useApiFormUserInvite()

  const notificationContext = useNotificationContext()

  let showUserInvite = useMemo(() => !!userLogin.inviteRegisterKey, [userLogin])

  let invitedOn = useMemo(() => moment(userLogin.inviteInvitedAt).format('DD-MMM-YYYY'), [userLogin])

  let registeredOn = useMemo(() => (userLogin.inviteRegisteredAt ? moment(userLogin.inviteRegisteredAt).format('DD-MMM-YYYY') : 'Unregistered'), [userLogin])

  let isRegistered = useMemo(() => !!userLogin.inviteRegisteredAt, [userLogin])

  const handleRegisterUser = async () => {
    await apiFormUserInvite.put(`${userLogin.inviteRegisterKey}/register`)
    notificationContext.toast.notify('User Registered', 3000)
    onUpdate && onUpdate()
  }

  const handleResendEmail = async () => {
    await apiFormUserInvite.put(`${userLogin.inviteRegisterKey}/email`)
    notificationContext.toast.notify('Invite email sent', 3000)
  }

  return (
    <React.Fragment>
      {showUserInvite &&
        <Card title="user invite">
          <LoginSysAdmin>
            <ItemReadOnly label="register key" value={userLogin.inviteRegisterKey} />
          </LoginSysAdmin>

          <ItemReadOnly label="invited" value={invitedOn} />
          <ItemReadOnly label="registered" value={registeredOn} />

          {!isRegistered &&
            <div className="m-2">
              <LoginSysAdmin>
                <ButtonPrimaryConfirm
                  Icon={IconCheck}
                  text="Register User"
                  small
                  confirmText="Are you sure you want to register user?"
                  onConfirm={handleRegisterUser}
                />
              </LoginSysAdmin>
              <ButtonPrimaryDelay Icon={IconEmail} text="re-send invite email" small onClick={handleResendEmail} />
            </div>}
        </Card>}
    </React.Fragment>
  )
}
