import React, { useMemo, useState } from 'react'
import { FileSelect } from '../legacy/file/file-select'
import { useLoad } from '../hooks/load'
import { FileToImageURL, ImageUrlToBlob } from './components/hook'
import { ImageCropper } from './components/image-cropper'
import { ImageThumbnail } from './components/image-thumbnail'
import { ButtonPrimary, ButtonSecondary } from '../component/button'

export const ImageEditor = ({ blob, width, height, cropWidthRatio, cropHeightRatio, maxSize, onSave, onDelete }) => {
  const [liveImageURL, updateLiveImageURL] = useState(null)
  const [previewImageURL, updatePreviewImageURL] = useState(null)
  const [editImageURL, updateEditImageURL] = useState(null)

  const showEdit = useMemo(() => !!editImageURL, [editImageURL])

  const hasLive = useMemo(() => !!liveImageURL, [liveImageURL])
  const hasPreview = useMemo(() => !!previewImageURL, [previewImageURL])

  useLoad(
    async () => {
      if (blob && blob.size) {
        let imageURL = await FileToImageURL(blob)
        updateLiveImageURL(imageURL)
      } else {
        updateLiveImageURL(null)
      }

      updatePreviewImageURL(null)
    },
    [blob]
  )

  const handleSelect = async file => {
    let imageURL = await FileToImageURL(file)
    updateEditImageURL(imageURL)
  }

  const handleSave = async () => {
    let blob = await ImageUrlToBlob(previewImageURL)
    onSave && (await onSave(blob))
    updatePreviewImageURL(null)
  }

  const handleCancel = () => {
    updatePreviewImageURL(null)
  }

  const handleEdit = () => {
    updateEditImageURL(previewImageURL)
  }

  const handleDelete = () => onDelete && onDelete()

  const handleContinueCropper = imageURL => {
    updatePreviewImageURL(imageURL)
    updateEditImageURL(null)
  }

  const handleCancelCropper = () => {
    updateEditImageURL(null)
  }

  let aspectWidth = useMemo(() => cropWidthRatio || 1, [cropWidthRatio])
  let aspectHeight = useMemo(() => cropHeightRatio || 1, [cropHeightRatio])

  return (
    <React.Fragment>
      {showEdit
        ? <React.Fragment>
            <div>EDIT</div>
            <ImageCropper
              imageURL={editImageURL}
              width={width}
              height={height}
              aspect={aspectWidth / aspectHeight}
              onContinue={handleContinueCropper}
              onCancel={handleCancelCropper}
            />
          </React.Fragment>
        : <React.Fragment>
            <div>
              <div className="border rounded p-2 m-2 d-inline-block">
                <ImageThumbnail imageURL={previewImageURL || liveImageURL} width={width} height={height} />
              </div>
            </div>

            <FileSelect onSelect={handleSelect} accept=".jpeg,.png" maxSize={maxSize} />

            <div>
              {!hasPreview && hasLive && <ButtonSecondary onClick={handleDelete}>delete</ButtonSecondary>}
              {hasPreview && <ButtonSecondary onClick={handleCancel}>cancel</ButtonSecondary>}
              {hasPreview && <ButtonPrimary onClick={handleEdit}>edit</ButtonPrimary>}
              {hasPreview && <ButtonPrimary onClick={handleSave}>save</ButtonPrimary>}
            </div>
          </React.Fragment>}

      <div>
        Image size ratio {aspectWidth}:{aspectHeight}
      </div>
    </React.Fragment>
  )
}
