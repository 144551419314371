import React, { useContext, useEffect, useMemo } from 'react'
import { AuthContext } from '../../context/auth.context'
import { useHistory } from '../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormWhiteLabel } from '../../api-new/state/content'

const logoPath = '/org/dashboard'

export const HeaderLogo = ({ small, gray }) => {
  const history = useHistory()

  const authContext = useContext(AuthContext)

  const apiFormWhiteLabel = useApiFormWhiteLabel()

  useEffect(
    () => {
      if (authContext.state.jwtClaims.organizationID) {
        apiFormWhiteLabel.get(authContext.state.jwtClaims.organizationID)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state.jwtClaims]
  )

  const logo = useMemo(() => (apiFormWhiteLabel.form.logoURL ? apiFormWhiteLabel.form.logoURL : '/EDXR-Logo.png'), [apiFormWhiteLabel.form])

  const handleClick = () => history.push(logoPath)

  return (
    <React.Fragment>
      <div className="d-inline" onClick={handleClick}>
        <img className={`edxr-pointer ${small ? 'logo-sm' : 'logo'} ${gray && 'logo-gray'}`} src={logo} alt="header logo" />
      </div>
    </React.Fragment>
  )
}
