import React, { useEffect } from 'react'
import { Route, useHistory, useLocation, useParams } from 'react-router-dom'
import { TradeShowRegisterForm } from './trade-show-register/trade-show-register-form'
import { TradeShowResult } from './trade-show-register/trade-show-result'
import { TradeShowInvalid } from './trade-show-register/trade-show-invalid'
import { Footer } from '../page/component/footer'
import { Card } from '../shared/card/card'
import { useLocationSearchObject } from '../shared/hooks/location'
import { useApFormTradeshow } from '../api-new/state/content'
import { useApiFormFileTradeshow } from '../api-new/state/storage'

const TradeShowHeroImage = ({ state }) => {
  return (
    <div className="row">
      <div className="mx-auto col-12 col-md-8 col-xl-6">
        <img className="w-100" src={state.form.logoURL} alt="logo" />
      </div>
    </div>
  )
}

export const TradeShowRegister = () => {
  const { identifier } = useParams()
  const history = useHistory()
  const { search } = useLocation()

  const data = useLocationSearchObject(['email:email', 'first-name:firstName', 'last-name:lastName'])

  const apFormTradeshow = useApFormTradeshow()
  const apiFormFileTradeshow = useApiFormFileTradeshow()

  useEffect(() => {
    apiFormFileTradeshow.get(identifier)
    history.replace(`/external/tradeshow/${identifier}/register${search}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSignup = async data => {
    await apFormTradeshow.postArg('/register', data)
    history.replace(`/external/tradeshow/${identifier}/result`)
  }

  const handleDismiss = () => history.replace(`/external/tradeshow/${identifier}/register`)

  return (
    <React.Fragment>
      <div className="d-flex flex-column h-100 p-2">
        <div className="flex-fill overflow-x-hidden overflow-y-scroll">
          <div className="container">
            <TradeShowHeroImage state={apiFormFileTradeshow} />

            <div className="mx-auto col-12 col-md-8 col-xl-6">
              <Card>
                <Route path="/external/tradeshow/:identifier/register">
                  <TradeShowRegisterForm identifier={identifier} actions={apFormTradeshow} state={apFormTradeshow} data={data} onSignup={handleSignup} />
                </Route>
                <Route path="/external/tradeshow/:identifier/result">
                  <TradeShowResult state={apFormTradeshow} onDismiss={handleDismiss} />
                </Route>
                <Route path="/external/tradeshow/:identifier/invalid">
                  <TradeShowInvalid state={apFormTradeshow} onDismiss={handleDismiss} />
                </Route>
              </Card>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  )
}
