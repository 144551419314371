import React, { useMemo } from 'react';
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context';
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y';
import { useParamsInt } from '../../../../../../shared/hooks/location';
import { Route, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { NodeCourseEdit } from './node-course-edit';
import { NodeCourseDelete } from './node-course-delete';
import { Grid, GridItem3 } from '../../../../../../shared/chart/grid';
import { CourseAssessment } from '../graphs/course-assessment';
import { AnalyticGeneralCourse } from '../graphs/analytic-general-course';
import { AnalyticCustomCourse } from '../graphs/analytic-custom-course';
import { CourseHeatmap } from '../graphs/course-heatmap';
import { IconCourse } from '../../../../../../shared/icon/icon';
import { NodeCourseMove } from './node-course-move';
import { NodeCourseCopy } from './node-course-copy';
import { AnalyticLayout } from '../../../../../../view/common/analytic/page/course/analytic-legacy/layout';
import { HeatmapLayoutNode } from '../../../../../../view/heatmap/layout-node';
import { AssessmentRoute } from '../../../../../../view/common/assessment/route';
import { AnalyticRoute } from '../../../../../../view/common/analytic/page/course/analytic-course-custom/route';
import { useDataContext } from '../../data-context';
import { NodeCourseEditSys } from './node-course-edit-sys';
import { LogEventOrgSubjectID } from '../../../../../../shared/page/log-event/log-event-org';
import { LoginSysAdmin } from '../../../../../../role/components/login-sys-admin';
import { CourseDeepLink } from '../../common/model/course-deep-link';
import { NodeCourseTemplate } from './node-course-template';
import { NodeContentMenuFull } from '../common/node-content-menu';
import { PermissionNode } from '../../common/permission/permission-node';
import { useRoleOrgAnalytic, useRoleOrgAssessmentManage } from '../../../../../../role/hook/role-org';
import { RoleOrgAnalytic, RoleOrgAssessmentReport, RoleOrgHeatmap } from '../../../../../../role/components/role-org';
import { AiDashboard } from '../../common/ai/ai-dashboard';
import { FeatureAI } from '../../../../../../shared/component/org-feature';

export const NodeCourseDetails = ({ parentURL }) => {
  const { url } = useRouteMatch();
  const { nodeID } = useParamsInt();

  const dataContext = useDataContext();

  const itemCurrent = useMemo(() => dataContext.nodes.find(i => i.id === nodeID) || {}, [dataContext.nodes, nodeID]);

  const roleOrgAssessmentManage = useRoleOrgAssessmentManage();
  const roleOrgAnalytic = useRoleOrgAnalytic();

  const HeaderMenu = () => NodeContentMenuFull(url, itemCurrent, true);

  return (
    <React.Fragment>
      <BreadcrumbView text={itemCurrent.name} Icon={IconCourse}>
        <CardFullHeightScrollY title={itemCurrent.name} HeaderMenu={HeaderMenu}>
          <Grid>
            <RoleOrgAssessmentReport accessRead>
              <GridItem3>
                <CourseAssessment nodeID={nodeID} showConfigButton={roleOrgAssessmentManage.accessRead} />
              </GridItem3>
            </RoleOrgAssessmentReport>
            <RoleOrgAnalytic accessRead>
              <GridItem3>
                <AnalyticGeneralCourse nodeID={nodeID} showConfigButton={roleOrgAnalytic.accessUpdate} />
              </GridItem3>
              <GridItem3>
                <AnalyticCustomCourse nodeID={nodeID} />
              </GridItem3>
            </RoleOrgAnalytic>
            <RoleOrgHeatmap accessRead>
              <GridItem3>
                <CourseHeatmap nodeID={nodeID} />
              </GridItem3>
            </RoleOrgHeatmap>
          </Grid>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      {itemCurrent &&
      <React.Fragment>
        <PermissionNode url={url} nodeID={nodeID} />

        <Route path={`${url}/move`}>
          <NodeCourseMove parentURL={url} nodeID={nodeID} />
        </Route>
      </React.Fragment>}

      <Route path={`${url}/edit`}>
        <NodeCourseEdit parentURL={url} nodeID={nodeID} />
      </Route>

      <LoginSysAdmin>
        <Route path={`${url}/edit-sys`}>
          <NodeCourseEditSys parentURL={url} nodeID={nodeID} />
        </Route>
      </LoginSysAdmin>

      <FeatureAI>
        <Route path={`${url}/ai`}>
          <AiDashboard nodeID={nodeID} />
        </Route>
      </FeatureAI>

      <Route path={`${url}/delete`}>
        <NodeCourseDelete grandParentURL={parentURL} parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/copy`}>
        <NodeCourseCopy nodeID={nodeID} parentURL={url} />
      </Route>

      <Route path={`${url}/template`}>
        <NodeCourseTemplate nodeID={nodeID} parentURL={url} />
      </Route>

      <Route path={`${url}/activity`}>
        <LogEventOrgSubjectID subject='node' subjectID={nodeID} />
      </Route>

      <AnalyticRoute />
      <AnalyticLayout />
      <HeatmapLayoutNode />
      <AssessmentRoute />

      <CourseDeepLink targetID='courseLink' nodeID={nodeID} />
    </React.Fragment>
  );
};
