import React, { useMemo, useState } from 'react'
import { ButtonPrimary, ButtonSecondary } from '../shared/legacy/button'
import { TermsAccept } from '../shared/legacy/legal/termsAccept'
import { useQueryParam } from '../shared/hooks/location'
import { CardFullHeightScrollY } from '../shared/card/card-full-height-scroll-y'
import { useApiFormAuthProvider } from '../api-new/state/auth'
import { AlertError } from '../shared/alert/alert-error'
import { useLoad } from '../shared/hooks/load'

export const OpenLink = () => {
  const token = useQueryParam('token')

  const [error, updateError] = useState()

  const [termsConditions, updateTermsConditions] = useState(false)

  const apiFormAuthProvider = useApiFormAuthProvider()

  const apiFormAuthProviderDetails = useApiFormAuthProvider()

  useLoad(async () => await apiFormAuthProviderDetails.get(`${token}/details`), [])

  const handleSubmit = async () => {
    try {
      await apiFormAuthProvider.get(`${token}/link`)

      let url = new URL(`${process.env.REACT_APP_SITE}/open/organization`)
      url.searchParams.set('token', token)
      window.location = url.href
    } catch (error) {
      debugger
      updateError(error)
    }
  }

  const handleCheck = termsConditions => updateTermsConditions(termsConditions)
  const handleCancel = async () => (window.location = apiFormAuthProviderDetails.redirectCancelURL)

  const title = useMemo(() => `Link account ${apiFormAuthProviderDetails.form.email} to ${apiFormAuthProviderDetails.form.provider}`, [
    apiFormAuthProviderDetails
  ])

  return (
    <React.Fragment>
      <CardFullHeightScrollY title={title}>
        <AlertError error={apiFormAuthProvider.error} />
        <AlertError error={error} />

        <div className="py-2">
          You are about to link this provider with your EducationXR account. Until you disconnect this account it will be able to be used to access to your
          EducationXR account. Please make sure that you trust this provider before linking your account.
        </div>

        <div className="py-2">
          <TermsAccept state={termsConditions} onCheck={handleCheck} />
        </div>

        <div className="py-2">
          <ButtonSecondary text="cancel" state={termsConditions} onClick={handleCancel} />
          <ButtonPrimary text="link account" disabled={!termsConditions} onClick={handleSubmit} />
        </div>
      </CardFullHeightScrollY>
    </React.Fragment>
  )
}
