import { useApiListConfig } from '../../../../../../../../api-new/state/ai'
import { Card } from '../../../../../../../../shared/card/card'
import { Form, FormCheckbox, FormSelectListStr } from '../../../../../../../../shared/form/form'
import { FormRangeFloat } from '../../../../../../../../shared/form/form-range-float'
import { useLoad } from '../../../../../../../../shared/hooks/load'

export const Settings = ({ value, onChange }) => {
  const apiListConfigModels = useApiListConfig()

  useLoad(() => apiListConfigModels.get('models'), [])

  return (
    <Card>
      <Form state={value} onChange={onChange}>
        <FormRangeFloat label="temperature" name="temperature" min="0" max="1" step="0.1" />
        <FormCheckbox label="reference documents" name="referenceDocuments" />
        <FormSelectListStr label="chat model" name="chatModel" state={apiListConfigModels.items} />
      </Form>
    </Card>
  )
}
