import React, { useMemo, useState } from 'react';
import { useApiFormDashboardAssessment } from '../../../../../../api-new/state/dashboard';
import { useApiListDashboardAssessment } from '../../../../../../api-new/state/dashboard';
import { CourseHeader, Footer, FooterButton, Widget } from '../../../../../../shared/chart/widget';
import { IconAssessment } from '../../../../../../shared/icon/icon';
import { ChartBar } from '../../../../../../shared/chart/chart';
import { chartColorMain } from '../../../../../../shared/chart/chart-color';
import { useLoad } from '../../../../../../shared/hooks/load';
import { useRouteMatch } from 'react-router-dom';

export const CourseAssessment = ({ nodeID, showConfigButton }) => {
  const { url } = useRouteMatch();

  const [count, updateCount] = useState(0);

  const apiListDashboardAssessment = useApiListDashboardAssessment();
  const apiFormDashboardAssessment = useApiFormDashboardAssessment();

  useLoad(
		async () => {
  let details = await apiFormDashboardAssessment.get(`course/${nodeID}/details`);
  updateCount(details.count);

  await apiListDashboardAssessment.get(`course/${nodeID}`);
},
		[nodeID]
	);

  const chartData = useMemo(
		() => {
  if (!apiListDashboardAssessment.items.length) {
    return { labels: [], data: undefined };
  }

  const labels = apiListDashboardAssessment.items.map(i => i.date);
  const data = apiListDashboardAssessment.items.map(i => i.count);

  const dataSet = {
    data: data,
    borderColor: chartColorMain,
    backgroundColor: chartColorMain,
    pointBackgroundColor: chartColorMain,
    pointBorderColor: chartColorMain,
    pointRadius: 1,
    borderWidth: 1,
    tension: 1.0,
    fill: true
  };

  return { labels: labels, data: dataSet };
},
		[apiListDashboardAssessment.items]
	);

  return (
    <Widget
      header={<CourseHeader Icon={IconAssessment} text='Assessments' value={count} />}
      body={<ChartBar labels={chartData.labels} data={chartData.data} displayX displayY />}
      footer={
        <React.Fragment>
          <Footer>
            <FooterButton text='Reports' to={`${url}/assessment-report/list/${nodeID}`} />
            {showConfigButton && <FooterButton text='Configure' to={`${url}/assessment-manage/list/${nodeID}`} />}
          </Footer>
        </React.Fragment>
			}
		/>
  );
};
