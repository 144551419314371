import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { useApiListLogEventOrg } from '../../../api-new/state/analytics'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../card/card-full-height-scroll-y'
import { useParamsInt } from '../../hooks/location'
import { IconActivityLog } from '../../icon/icon'
import { Menu, MenuItem } from '../../menu/menu'
import { Column, ColumnDateTime, ColumnFilter, ColumnMenu, TableBuilder } from '../../table-builder/table-builder'
import { LogEventDetails } from './log-event-details'

export const LogEventOrg = ({ subject }) => {
	const { id } = useParamsInt()

	const { url } = useRouteMatch()

	const apiListLogEventOrg = useApiListLogEventOrg()

	const handleReady = () => {
		if (id) apiListLogEventOrg.get(`${subject}/subject/${id}`)
		else apiListLogEventOrg.get(`${subject}/subject`)
	}

	return (
		<React.Fragment>
			<BreadcrumbView Icon={IconActivityLog} text='activity log'>
				<CardFullHeightScrollY title='Activity Log'>
					<TableBuilder apiList={apiListLogEventOrg} onReady={handleReady}>
						<ColumnDateTime field='createdAt' header='Occured' sortable filter />
						<Column field='target' sortable filter />
						<Column field='email' sortable filter />
						<ColumnFilter field='method' sortable filter />
						<Column field='ipAddress' sortable filter />
						<ColumnMenu
							menu={(row) => (
								<Menu small>
									<MenuItem text='details' to={`${url}/${row.id}/details`} show />
								</Menu>
							)}
						/>
					</TableBuilder>
				</CardFullHeightScrollY>
			</BreadcrumbView>

			<Route path={`${url}/:logEventID/details`}>
				<LogEventDetails subject={subject} />
			</Route>
		</React.Fragment>
	)
}

export const LogEventOrgSubjectID = ({ subject, subjectID }) => {
	const { url } = useRouteMatch()

	const apiListLogEventOrg = useApiListLogEventOrg()

	const handleReady = () => {
		if (subjectID) apiListLogEventOrg.get(`${subject}/subject/${subjectID}`)
		else apiListLogEventOrg.get(`${subject}/subject`)
	}

	return (
		<React.Fragment>
			<BreadcrumbView Icon={IconActivityLog} text='activity log'>
				<CardFullHeightScrollY title='Activity Log'>
					<TableBuilder apiList={apiListLogEventOrg} onReady={handleReady}>
						<ColumnDateTime field='createdAt' header='Occured' sortable filter />
						<Column field='target' sortable filter />
						<Column field='email' sortable filter />
						<ColumnFilter field='action' sortable filter />
						<Column field='ipAddress' sortable filter />
						<ColumnMenu
							menu={(row) => (
								<Menu small>
									<MenuItem text='details' to={`${url}/${row.id}/details`} show />
								</Menu>
							)}
						/>
					</TableBuilder>
				</CardFullHeightScrollY>
			</BreadcrumbView>

			<Route path={`${url}/:logEventID/details`}>
				<LogEventDetails />
			</Route>
		</React.Fragment>
	)
}
