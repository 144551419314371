import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuEdit, MenuItem } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListSysAdmin } from '../../../../../api-new/state/content'
import { IconPromote, IconDemote, IconUsers } from '../../../../../shared/icon/icon'

export function SysAdminList() {
	const apiListSysAdmin = useApiListSysAdmin()

	const handleReady = async () => await apiListSysAdmin.get()

	const handleDemote = async (value) => {
		await apiListSysAdmin.put(`${value}/demote`)
		await apiListSysAdmin.get()
	}

	const HeaderMenu = () => (
		<Menu>
			<MenuItem Icon={IconPromote} text='promote' to='/root/sys-admin/list/promote' show />
		</Menu>
	)

	return (
		<React.Fragment>
			<BreadcrumbView Icon={IconUsers} text='SYS ADMIN'>
				<CardFullHeightScrollY title='sys admins' HeaderMenu={HeaderMenu}>
					<TableBuilder apiList={apiListSysAdmin} onReady={handleReady}>
						<Column field='email' sortable filter />
						<Column field='firstName' sortable filter />
						<Column field='lastName' sortable filter />
						<ColumnMenu
							menu={(row) => (
								<Menu small>
									<MenuEdit to={`/root/sys-admin/list/edit/${row.id}`} show />
									<MenuItem Icon={IconDemote} text='demote' onClick={() => handleDemote(row.id)} show />
								</Menu>
							)}
						/>
					</TableBuilder>
				</CardFullHeightScrollY>
			</BreadcrumbView>
		</React.Fragment>
	)
}
